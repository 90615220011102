/* app/not-found/not-found.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 14px;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 18px;
    }
    
  }
  @media screen and (max-width: 576px) {
    .title {
      font-size: 18px;
    }
    
  }
  
